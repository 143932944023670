<template>
  <div class="wrapper">
    <p>Unterschrift</p>
    <canvas id="canvas" class="signature-pad"></canvas>
    <button class="btn-clear img-btn" @click="undoSignature">
      <fa-icon icon="undo" />
    </button>
    <button class="btn-accept img-btn" @click="acceptSignature">
      <fa-icon icon="check" />
    </button>
  </div>
</template>

<script>
import SignaturePad from '../../../node_modules/signature_pad/dist/signature_pad'

export default {
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  data: () => ({
    signaturePad: {},
    signatureCanvas: {},
    resultSignature: String,
  }),
  methods: {
    submit(event) {
      event.preventDefault()
    },
    undoSignature() {
      this.signaturePad.clear()
    },
    acceptSignature() {
      if (!this.signaturePad.isEmpty()) {
        this.resultSignature = this.signaturePad.toDataURL()

        this.$emit('action', {
          image: this.resultSignature,
          width: this.signatureCanvas.width,
          height: this.signatureCanvas.height,
        })
      }
    },
    initSignaturePad() {
      this.handleResize()

      var minWidth = 2
      var maxWidth = 4

      if (window.innerWidth > 768) {
        minWidth = 5
        maxWidth = 9
      }

      this.signaturePad = new SignaturePad(this.signatureCanvas, {
        penColor: 'rgb(95, 168, 211)',
        minWidth: minWidth,
        maxWidth: maxWidth,
      })
    },
    handleResize() {
      this.signatureCanvas.width = window.innerWidth
      this.signatureCanvas.height = window.innerHeight
    },
  },
  mounted() {
    this.signatureCanvas = document.querySelector('canvas')
    this.initSignaturePad()
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

#canvas {
  position: relative;
  top: 0;
  left: 0;
}

.signature-pad {
  border: 1px solid #ccc;
}

.btn-clear {
  position: absolute;
  left: 25px;
  bottom: 35px;
}

.btn-accept {
  position: absolute;
  right: 25px;
  bottom: 35px;
}
</style>
